import { Rating } from 'react-simple-star-rating'
import LocalisedText from './LocalisedText';

const Review = (props) => {
    const review = props.review;

    return(
        <div className='item-review'>
            <Rating initialValue={review.rating} readonly />
            <p><LocalisedText value={review.comment} onEditTranslation={props.onEditTranslation}/></p>
        </div>
    );
}

export default Review;
