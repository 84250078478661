import { useState } from 'react';
import { Rating } from 'react-simple-star-rating'
import "./styles/Form.css";

const FormField = (props) => {
    const [focused, setFocused] = useState(false);
    const { label, errorMessage, onChange, id, lastField, initialValue, ...inputProps } = props;

    const handleFocus = (e) => {
        setFocused(true);
    }

    const handleRatingClick = (value) => {
        console.log(value);
        // Simulate onChange message
        onChange(
            { 
                target: {
                    name: "rating",
                    value: value / 20
                }
            }
        );
    };

    if(inputProps.type==="rating") {
        return (
            <div className='form-input'>
                <label>{label}</label>
                <Rating name={inputProps.name} value={inputProps.value} onClick={handleRatingClick} />

            </div>
        );
    }

    if(inputProps.type==="textarea") {
        return(
            <div className="form-input">
                <label>{label}</label>
                <textarea 
                    {...inputProps} 
                    onChange={onChange} 
                    onBlur={handleFocus}
                    onFocus={() =>
                        lastField && setFocused(true)
                    }
                    focused={focused.toString()} />
                <span>{errorMessage}</span>
            </div>
        );
    }

    return(
        <div className="form-input">
            <label>{label}</label>
            <input 
                {...inputProps} 
                onChange={onChange} 
                onBlur={handleFocus}
                onFocus={() =>
                    lastField && setFocused(true)
                }
                focused={focused.toString()} 
            />
            <span>{errorMessage}</span>
        </div>
    );
}

export default FormField;