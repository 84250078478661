import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';

// The RouteGuard component shows or hides content based on the user's role.
// The roles are passed in as a prop and are compared to the user's roles in the idTokenClaims.
// If the user has any of the roles passed in, the content is shown.

// IMPORTANT: You must protect any server-side API calls with the same role-based authorization.
// This is only a client-side check and can be bypassed by modifying the client-side code.
export const RouteGuard = ({ ...props }) => {
    const { instance, accounts } = useMsal();
    const [isAuthorized, setIsAuthorized] = useState(false);

    const onLoad = async () => {
        const currentAccount = accounts && accounts[0];
        if (currentAccount && currentAccount.idTokenClaims['roles']) {
            let intersection = props.roles.filter((role) => currentAccount.idTokenClaims['roles'].includes(role));

            if (intersection.length > 0) {
                setIsAuthorized(true);
            }
        }
    };

    useEffect(() => {
        onLoad();
    }, [instance]);
    return (
        <>
            {isAuthorized &&
                <div>{props.children}</div>
            }
        </>
    );
};