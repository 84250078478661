import { Link as LinkR} from "react-router-dom";
import { Link as LinkS} from "react-scroll";
import { Container } from "react-bootstrap"
import styled from "styled-components";


export const Nav =  styled(Container)`
 background: #FAFAFA;
 margin-top: 0px;
 margin-left: 0px;
 font-size: 12px;
 top: 0;
 z-index:10;
 max-width: 100%;

 @media screen and (max-width: 768px) { 
      position: relative !important;
 }`;

export const NavbarContainer =  styled.div`
 height: 100px;
 display: flex;
 justfy-content: space-between;
 z-index:1;
 width: 100%;
 padding: 0 24px;
 max-width: 1100px;
 }
`;

export const Navlogo =  styled(LinkR)`
 color: #fff;
 height: 80px;
 display: flex;
 justfy-self: flex-start;
 z-index:1; 
 align-items:center;  
 margin-left: 24px;
 font-weight: bold;
 text-decoration: none;
 }
`;

export const NavReview =  styled.p`
 color: #00008B;
 font-family: 'FujitsuInfinityPro-Bold';
 text-align: left;
 margin-left: 0px;
 font-size: 15px;

 }
`;

export const NavMenu =  styled.ul`
 display: flex; 
 align-items: baseline; 
 background: #fff;
 list-style: none;
 text-align: left;
 z-index:1;
 
 

 @media screen and (max-width: 768 px){
  display: none;
 }
`;

export const NavItem = styled.li`
 height: 100%;
 align-items: flex-end; 
`;

export const NavLinks =  styled(LinkS)`
 color: #B2BEB5;
 display: flex;
 text-decoration: none;
 text-align: center;
 padding: 0 4.5rem;
 height: auto;
 cursor: pointer;
 font-size: 16px;
 font-family: 'FujitsuInfinityPro-Bold';
 
 
 &.active{
  color: #00008B;
  border-bottom: 3px solid #008000;
 
 }

`;

export const NavBtn = styled.nav`
 display: flex;
 align-items: center;

 @media screen and (max-width: 768px){
  display: none;
 }`;

export const NavBtnLink = styled(LinkR)`
 border-radius: 50px;
 background: #01bf7;
 white-space: nowrap;
 padding: 10px 22px;
 color: #010606;
 font-size: 16px;
 outline: none;
 border: none;
 cursor: pointer;
 transition: all 0.2s easy-in-out;
 text-decoration: none;

 &.hover {
  transition: all 0.2s easy-in-out;
  backgroud: #fff;
  color: #010606; 
 }
`;

export const DivContainer =  styled.div`
 background: #fff;
 height: 100%;
 justfy-content: center;
 align-items: center;
 z-index:1;
 width: 100%;
 padding: 0 30px;
 position: relative;
 border-style: outset;
 top: 0;
 
 }
`;

export const TitleHeader =  styled.h1`
 color: #000000;
 font-size: 24px;
 text-align: left;
 position: left;
 font-family: 'FujitsuInfinityPro-ExtraBold';

 @media screen and (max-width: 768px){
  font-size: 26px;
 }
 
 @media screen and (max-width: 480px){
  font-size: 18px;
 }
 
`;

export const Header =  styled.h1`
 color: #FF00FF;
 font-size: 24px;
 text-align: center;
 padding: 0 5px;
 font-family: 'FujitsuInfinityPro-Bold';

 @media screen and (max-width: 768px){
  font-size: 26px;
 }
 
 @media screen and (max-width: 480px){
  font-size: 18px;
 }
 
`;

export const Header1 =  styled.h1`
color: #ADD8E6;
text-align: left;
font-family: 'FujitsuInfinityPro-Bold'; 
top: 0;
font-size: 30px;

 @media screen and (max-width: 768px){
  font-size: 20px;
 }
 
 @media screen and (max-width: 480px){
  font-size: 14px;
 }
 
`;

export const Paragraph = styled.p`
  color: #000000;
  font-size: 15px;
  text-align: left;
  display:flex;
  position: left;
  font-family: 'FujitsuInfinityPro-Regular';
   
 @media screen and (max-width: 768px){
  font-size: 10px;
 }
 @media screen and (max-width: 480px){
  font-size: 6px;
 }
`;

export const LogoImage = styled.img`
width: 150px;
`;

export const RefernceLinks  = styled.ul`

color: #ADD8E6;
font-size: 15px;
text-align: left;
display:flex;
position: left;
border-style: inset;
list-style-type: inside;
font-family: 'FujitsuInfinityPro-Regular';

@media screen and (max-width: 768px){
     font-size: 10px;
}
@media screen and (max-width: 480px){
     font-size: 6px;
}

`


 