import { React, useState } from "react";
import {  Nav,  NavItem, NavLinks, NavMenu, TitleHeader, Paragraph, LogoImage} from './NavibarElements';
import { useParams } from 'react-router-dom';
import { useFetch } from './UseFetch';
import Spinner from './Spinner';
import { Trans } from 'react-i18next';
import ShowReviews from './ShowReviews';
import LocalisedText from './LocalisedText';
import AsyncLocalisedText from "./AsyncLocalisedText";
import { animateScroll as scroll , scroller} from 'react-scroll';


const Navbar = () => { 
   
    const { id } = useParams();
	const [isEditingTranslation, setIsEditingTranslation] = useState(false);    
	const [translationId, setTranslationId] = useState(0);

    const baseUrl =
		process.env.REACT_APP_CATALOGUE_API_URL;
	const [fetchUrl, setFetchUrl] = useState(`${baseUrl}/details/${id}`);
	const { data: item, isPending, error } = useFetch(fetchUrl);
    
    if (isPending) return <Spinner />;
	if (error) throw error;
	const toggleHome = () =>{
		scroll.scrollToTop()
	}

	const handleEditTranslation = (id) => {
		setTranslationId(id);
		setIsEditingTranslation(true);
	};

	


    return(
        <>
        <Nav className="position-sticky ">
				<div className="row ">
					<div className="col-sm-4" to ="#" onClick= {toggleHome}>
												
						{/* <div className="media" to ="#" onClick= {toggleHome}> */}
							<LogoImage  src={item.imageUrl || '/defaultitem.jpg'}
									alt={item.name.text}
										className='img-fluid'
								/> 
																				
									
														
						{/* </div> */}
						</div>
					
						<div className="col-sm-8">
							<div>
							<TitleHeader >												
									<LocalisedText
										value={item.name}
										onEditTranslation={handleEditTranslation} 
									/>
							</TitleHeader>
							</div>
							
							<Paragraph><AsyncLocalisedText id={id} selector="description" onEditTranslation={handleEditTranslation} /></Paragraph>
							<ShowReviews
								item={item}
								onEditTranslation={handleEditTranslation}
							/>
							
						
						</div>
					</div>
					<div className="row ">
						<div className="col-sm">							
														
							<div className="d-none d-lg-block ">
							<NavMenu >
									
								<NavItem>
									<NavLinks to='repository' smooth={true} duration ={500} spy={true} exact='true' offset={-320}  ><Trans i18nKey='details.overview' /></NavLinks>									
								</NavItem>
								<NavItem>
									<NavLinks to='survey'  smooth={true} duration ={500} spy={true} exact='true' offset={-320} ><Trans i18nKey='details.survey'/></NavLinks>									
								</NavItem>
								<NavItem>
									<NavLinks to='documentation'  smooth={true} duration ={500} spy={true} exact='true' offset={-320} ><Trans i18nKey='details.documentation'/></NavLinks>									
								</NavItem>
								<NavItem>
									<NavLinks to='relationship'  smooth={true} duration ={500} spy={true}  exact='true' offset={-320} ><Trans i18nKey="relationships.title"/></NavLinks>									
								</NavItem>
								<NavItem>
									<NavLinks to='reviews' smooth={true} duration ={500} spy={true} exact='true' offset={-320} ><Trans i18nKey="review.heading"/></NavLinks>
								</NavItem>
							</NavMenu>	
							</div>	
							
						</div>	
					
				</div>
			
			</Nav>
							
			
        </>

    );


};

export default Navbar;
