import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import { Container, Nav, NavDropdown } from "react-bootstrap"; 
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { useTranslation, Trans } from 'react-i18next';
import i18n, { languages } from './i18n';
import "./styles/Navigation.css";

function handleLogin(instance) {
  instance.loginRedirect(loginRequest).catch(e => {
      console.error(e);
  });
}

function handleLogout(instance) {
  instance.logoutRedirect().catch(e => {
    console.error(e);
  });
}

function toggleLanguage() {
  const newLang = i18n.resolvedLanguage === "en" ? "jp" : "en";
  i18n.changeLanguage(newLang);
}

export const Navigation = () => {
 const { t, i18n } = useTranslation();
 const { instance } = useMsal();
 const isAuthenticated = useIsAuthenticated();
 
 return (
   <Navbar fixed="top" bg="dark" variant="dark">
    <Container>
      <Navbar.Brand href="/">
        <img
          alt=""
          src="/logo.svg"
          width="30"
          height="30"
          className="d-inline-block align-top"
        />{' '}
        <span  className="app-name"><Trans i18nKey="brand.appname">App Name</Trans></span>
      </Navbar.Brand>
    </Container>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
     <Navbar.Collapse className="ms-auto" id="basic-navbar-nav">
       <Nav className="ms-auto language-toggle" 
          onClick={() => toggleLanguage()}>
            <div className="language-select">
              <img
                alt=""
                src="/flag-jp.svg"
                width="30"
                height="20"
                className={i18n.resolvedLanguage === "jp" ? "flag-jp foreground-flag" : "flag-jp background-flag"}
              />   
              <img id="flag-en"
                alt=""
                src="/flag-en.svg"
                width="30"
                height="15"
                className={i18n.resolvedLanguage === "en" ? "flag-en foreground-flag" : "flag-en background-flag"}
              />   
            </div>
        </Nav>
        <Nav className="auth-link">
         { isAuthenticated ? 
           <Nav.Link variant="transparent" onClick={() => handleLogout(instance)}><Trans i18nKey="auth.signout">Sign Out</Trans></Nav.Link> :
           <Nav.Link variant="transparent" onClick={() => handleLogin(instance)}><Trans i18nKey="auth.signin">Sign In</Trans></Nav.Link> }
       </Nav>
     </Navbar.Collapse>
   </Navbar>
 );
}
