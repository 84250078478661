import { PencilIcon } from '@primer/octicons-react'
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from 'react-i18next';

const LocalisedText = (props) => {
    const { t } = useTranslation();
    const item = props.value;
    const id = item.id;
    const text = item.text;
    const canEdit = item.canEdit;
    const isReadOnly = props.readonly;
    const onEditTranslation = props.onEditTranslation;

    const renderTooltip = props => (
        <Tooltip {...props}>{t("buttons.editTranslationTooltip","Edit this text")}</Tooltip>
    );

    const handleEditText = () => {
        console.log("Going to edit " + id);
        onEditTranslation(id);
    }

    return(
        <>{text}{canEdit && 
            !isReadOnly && 
            <OverlayTrigger 
                placement="top" 
                overlay={renderTooltip} 
                delay={{ show: 250, hide: 400 }}>
                <Button 
                    className="edit-localised-text-button"
                    onClick={handleEditText}>
                        <PencilIcon />
                </Button>
            </OverlayTrigger>}
        </>
    );
}

export default LocalisedText;