import { useFetch } from './UseFetch';
import Spinner from './Spinner';
import Review from './Review';
import { Rating } from 'react-simple-star-rating';
import { useTranslation, Trans } from 'react-i18next';
import './styles/ComposableItem.css';
import Carousel from 'react-bootstrap/Carousel';
import {  NavReview} from './NavibarElements';
import { Row, Col } from 'react-bootstrap';


const ShowReviews = (props) => {
	const { t, i18n } = useTranslation();
	const item = props.item;
	const id = item.id;
	const rating = item.rating;
	const numberOfReviews = item.numberOfReviews;
	const baseUrl =
		process.env.REACT_APP_CATALOGUE_API_URL;
	const {
		data: reviews,
		isPending,
		error,
	} = useFetch(`${baseUrl}/reviews/${id}`);

	if (isPending) return <Spinner />;
	if (error) throw error;

	return (
		<div>
			{/* <p align="left"  > */}
				{/* <Trans i18nKey='reviews.overall'  /> : */}
				<NavReview> 
				<Rating size={24} initialValue={rating} allowFraction='true' readonly  />	

				| <Trans
					i18nKey='reviews.based-on'
					values={{ numberOfReviews: numberOfReviews }}
				/></NavReview>
				
			{/* </p> */}
			
			
			{/* <div >
				<Carousel >
				{reviews.map((review) => (
					
						<Carousel.Item>	
							<Review
								key={review.id}
								review={review}
								onEditTranslation={props.onEditTranslation} />
			    			&nbsp;														
						</Carousel.Item>	            
        			
				))}
				</Carousel>
				
			</div>
				 */}
			
		</div>
	);
};

export default ShowReviews;
