import { useState } from 'react';
import { useFetch } from './UseFetch';
import Spinner from './Spinner';
import ComposableItem from './ComposableItem';
import ComposableListItem from './ComposableListItem';
import { Container, Row, ToggleButtonGroup } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import './styles/ComposableItem.css';

const ComposableItems = (props) => {
	const [listView, setListView] = useState(false);
	const tags = props.selectedTags;
	const rating = props.filterRating;
	const searchTerm = props.searchTerm;
	const term = encodeURIComponent(tags.join(','));
	console.log("REACT_APP_CATALOGUE_API_URL: " + process.env.REACT_APP_CATALOGUE_API_URL);
	const baseUrl =
		process.env.REACT_APP_CATALOGUE_API_URL;
	const {
		data: items,
		isPending,
		error,
	} = useFetch(
		baseUrl +
			'/search?rating=' +
			rating +
			'&selectedTags=' +
			term +
			'&term=' +
			searchTerm
	);

	if (isPending) return <Spinner />;
	if (error) throw error;

	const handleViewChange = (val) => {
		setListView(val);
	}

	if(listView) {
		return (
			<Container fluid className='composable-items-list'>
				<Row className="view-select">
					<ToggleButtonGroup name="selected-view" type="radio" value={1}>
						<ToggleButton value={0} variant="outline-success">
							<img src="/icon-grid.png" alt="Grid View" 
								onClick={(e) => handleViewChange(false)}/>
						</ToggleButton>
						<ToggleButton value={1} variant="outline-success">
							<img src="/icon-list.png" alt="List View"/>
						</ToggleButton>
					</ToggleButtonGroup>
				</Row>
				{items.map((item) => (
					<ComposableListItem key={item.id} item={item} />
				))}
			</Container>
		);
	}
	else {
		return (
			<Container fluid className='composable-items'>
				<Row className="view-select">
					<ToggleButtonGroup name="selected-view" type="radio" value={0}>
						<ToggleButton value={0} variant="outline-success">
							<img src="/icon-grid.png" alt="Grid View"/>
						</ToggleButton>
						<ToggleButton value={1} variant="outline-success" 
							onClick={(e) => handleViewChange(true)}>
							<img src="/icon-list.png" alt="List View"/>
						</ToggleButton>
					</ToggleButtonGroup>
				</Row>
				<Row>
					{items.map((item) => (
						<ComposableItem key={item.id} item={item} />
					))}
				</Row>
			</Container>
		);
	}
};

export default ComposableItems;
