import RadarChart from 'react-svg-radar-chart';
import 'react-svg-radar-chart/build/css/index.css'
import { useTranslation } from 'react-i18next';
import { useFetch } from './UseFetch';
import Spinner from './Spinner';
import { Row, Col } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import styled from "styled-components";
import { RouteGuard } from './RouteGuard';
import { appRoles } from './authConfig';
import { Header,  Paragraph } from './NavibarElements';
import './styles/Survey.css';

export const SurveyContainer =  styled.div`
 display: flex;
 justfy-content: space-between;
 z-index:1;
 width: 100%;
 padding: 0 24px;
 text-align: left;
 }
`;

export const ThemeHeader =  styled.h2`
 color: #666666;
 font-size: 1.4em;
 padding: 5px 0;
 font-family: 'FujitsuInfinityPro-Bold';
 @media screen and (max-width: 768px){
    font-size: 1.4em;
   }
   @media screen and (max-width: 480px){
    font-size: 1.2em;
   }
`;

export const ScoreHeader =  styled.h3`
 color: #333333;
 font-size: 1.2em;
 padding: 5px 0;
 font-family: 'FujitsuInfinityPro-Bold';
 @media screen and (max-width: 768px){
  font-size: 1.2em;
 }
 @media screen and (max-width: 480px){
  font-size: 1em;
 }
`;

export const ThemeSeparator = styled.hr`
color: #999999;
background-color: #999999;
height: 4px!important;
`;


const Survey = (props) => {
    const { t } = useTranslation();
	const baseUrl =
		process.env.REACT_APP_ASSESSMENTS_API_URL;
    const surveyUrl = `${baseUrl}/embedded/${props.selector}`;
    console.log("Survey URL:", surveyUrl);
    const { data, isPending, error, notFound } = useFetch(
        surveyUrl
    );

    if (isPending) return <Spinner />;
	if (error) throw error;

    if( notFound || data.assessment === null ) {
        const newSurveyUrl = (notFound || data.latestVersionId === 0) ? `${process.env.REACT_APP_ASSESSMENTS_CLIENT_URL}/new?selector=${encodeURIComponent(props.selector)}&name=${encodeURIComponent(props.name)}`.replace("{host}", window.location.hostname)
            : `${process.env.REACT_APP_ASSESSMENTS_CLIENT_URL}/assessments/${data.latestVersionId}`.replace("{host}", window.location.hostname);
        const assessorPrompt = (notFound || data.latestVersionId === 0) ? "But you can create one!" : "But you can edit the latest draft!";
        return(
            <>
                <p>There is no maturity assessment for this item.</p>
                <RouteGuard roles={[appRoles.Assessor]}>

                    <p><a href={newSurveyUrl} target="_blank" rel="noreferrer">{assessorPrompt}</a></p>
                </RouteGuard>
            </>);
    }

    var lastTheme = "";

    const editSurveyUrl = `${process.env.REACT_APP_ASSESSMENTS_CLIENT_URL}/assessments/${data.assessment.id}`.replace("{host}", window.location.hostname);
    const editLatestSurveyUrl = `${process.env.REACT_APP_ASSESSMENTS_CLIENT_URL}/assessments/${data.latestVersionId}`.replace("{host}", window.location.hostname);
    debugger;
    return(
        <>
        <SurveyContainer>
            <Row>
        <Col xs={4}>

        <RadarChart
            className="radar"
            captions={{
                discovery: t("assessment.discoverability","Discovery"),
                modularity: t("assessment.modularity","Modularity"),
                autonomy: t("assessment.autonomy","Autonomy"),
                orchestration: t("assessment.orchestration","Orchestration")
            }}
            data={data.assessment.radarDataPivot}
            options={{
                rotation: -135
            }}
            size={600}
          />        
        </Col>
        <Col>
            <RouteGuard roles={[appRoles.Assessor]}>
                <Paragraph>As an Assessor, you can&nbsp;<a href={editSurveyUrl} target="_blank" rel="noreferrer">edit this Assessment</a>
                {data.latestVersionId !== data.assessment.id && <>&nbsp;or&nbsp;<a href={editLatestSurveyUrl} target="_blank" rel="noreferrer">edit the latest draft</a>.</>}
                </Paragraph>
            </RouteGuard>

            {data.assessment.themes.sort(function(a, b){return a.sortOrder - b.sortOrder}).map((theme) => (
                <>
                    <ThemeSeparator />
                    <ThemeHeader>{lastTheme = theme.name}</ThemeHeader>
                    {theme.scores.sort(function(a, b){return a.sortOrder - b.sortOrder}).map((score) => (
                        <>
                        <ScoreHeader className={score.criteria.toLowerCase().replace(' ', '-')}>{score.criteria} ({score.score})</ScoreHeader>
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{(score.comment) ? score.comment : t("assessment.nocomment","The assessor has not provided a comment.")}</ReactMarkdown>
                        </>
                    ))}
                </>
            ))}
        </Col>
        </Row>
        </SurveyContainer>
        </>
    );
}

export default Survey;