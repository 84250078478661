import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	useMsal,
} from '@azure/msal-react';
import MetaTagList from './MetaTagList';
import ComposableItems from './ComposableItems';
import { Row, Col } from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating';
import FormField from './FormField';
import { loginRequest } from './authConfig';
import LanguageTemplate from './LanguageTemplate';
import { ErrorBoundary } from 'react-error-boundary';

function ErrorFallback({ error, resetErrorBoundary }) {
	return (
		<div role='alert'>
			<p>Something went wrong:</p>
			<pre>{error.message}</pre>
			<button onClick={resetErrorBoundary}>Try again</button>
		</div>
	);
}

const Home = () => {
	const { t } = useTranslation();
	const { instance } = useMsal();

	const [selectedTags, setSelectedTags] = useState([]);
	const [filterRating, setFilterRating] = useState(0);
	const [searchTerm, setSearchTerm] = useState('');
	const [activeSearchTerm, setActiveSearchTerm] = useState('');
	var timeoutHandle = null;
	function toggleSelectedTag(tag) {
		setSelectedTags((tags) => {
			const tagInList = tags.find((tg) => tg === tag);
			if (tagInList) return tags.filter((tg, _) => tg !== tag);
			return [...tags, tag];
		});
	}

	function handleLogin(instance) {
		instance.loginRedirect(loginRequest).catch((e) => {
			console.error(e);
		});
	}

	const handleRating = (rate) => {
		setFilterRating(rate);
	};

	const handleClearRating = () => {
		setFilterRating(0);
	};

	const onChangeSearchTerm = (e) => {
		setSearchTerm(e.target.value);
		if (timeoutHandle) clearTimeout(timeoutHandle);
		timeoutHandle = setTimeout(() => {
			setActiveSearchTerm(e.target.value);
		}, 1000);
	};

	return (
		<div className='Home'>
			<AuthenticatedTemplate>
				<Row>
					<Col lg='3'>
						<ErrorBoundary FallbackComponent={ErrorFallback}>
							<MetaTagList toggleTag={toggleSelectedTag} />
						</ErrorBoundary>
						<div>
							<p>
								<Trans i18nKey='filters.rating.title'>Filter by Rating</Trans>
							</p>
							<Rating initialValue={filterRating} onClick={handleRating} />
							{filterRating >= 1 && (
								<button onClick={handleClearRating}>
									<Trans i18nKey='filters.rating.clear'>Clear</Trans>
								</button>
							)}
						</div>
						<div>
							<p>
								<Trans i18nKey='filters.text'>Search Text</Trans>
							</p>
							<FormField value={searchTerm} onChange={onChangeSearchTerm} />
						</div>
					</Col>
					<Col lg='9'>
						<ErrorBoundary FallbackComponent={ErrorFallback}>
							<ComposableItems
								selectedTags={selectedTags}
								filterRating={filterRating}
								searchTerm={activeSearchTerm}
							/>
						</ErrorBoundary>
					</Col>
				</Row>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<Row>
					<LanguageTemplate languages='en'>
						<h1>Welcome to the Composable Services Catalogue.</h1>
						<p>
							Here, you can discover components, services, Packaged Business
							Components and other assets that can be composed into larger
							solutions.
						</p>
						<p>Access is restricted to Fujitsu staff and authorised agents.</p>
						<p>
							<button onClick={() => handleLogin(instance)}>
								Please sign in
							</button>
						</p>
					</LanguageTemplate>
					<LanguageTemplate languages='jp'>
						<h1>コンポーザブル サービス カタログへようこそ。</h1>
						<p>
							ここでは、より大きなソリューションに構成できるコンポーネント、サービス、パッケージ化されたビジネス
							コンポーネント、およびその他の資産を見つけることができます。
						</p>
						<p>
							アクセスは、富士通のスタッフおよび認定エージェントに制限されています。
						</p>
						<p>
							<button onClick={() => handleLogin(instance)}>
								ログインしてください
							</button>
						</p>
					</LanguageTemplate>
				</Row>
			</UnauthenticatedTemplate>
		</div>
	);
};

export default Home;
