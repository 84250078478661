import { useState, useEffect } from 'react';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from 'react-i18next';

export const useFetch = (url) => {
    const { instance, accounts } = useMsal();
    const { i18n } = useTranslation();
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [error, setError] = useState(null);
    const [currentLanguage, setCurrentLanguage] = useState(i18n.resolvedLanguage);

    const location = window.location;

    console.log("useFetch", url);

    url = url.replace("{host}", location.hostname);

    console.log("useFetch", url);
    useEffect(() => {
        const abortController = new AbortController();
        const accessTokenRequest = {
            scopes: ["api://170acadd-bd1d-4283-a67e-c51b61b4ef10/Api.Read"],
            account: accounts[0]
        }

        console.log("Calling API");
        setIsPending(true);
        instance.acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;
                // Call your API with token
                const headers = new Headers();
                const bearer = `Bearer ${accessToken}`;
            
                headers.append("Authorization", bearer);
                headers.append("Language", i18n.resolvedLanguage);
                console.log("Requesting language", i18n.resolvedLanguage);
            
                const options = {
                    method: "GET",
                    headers: headers,
                    signal: abortController.signal
                };
                console.log("Fetching tags", i18n.resolvedLanguage);
                fetch(url, options)
                .then(res => {
                    console.log("Fetch: ", res.statusText);
                    if(res.ok) {
                        setCurrentLanguage(i18n.resolvedLanguage);
                        return res.json();
                    } else {
                        if(res.status === 404) {
                            setNotFound(true);
                            setCurrentLanguage(i18n.resolvedLanguage);
                            return null;
                        }

                        throw Error('Something went wrong');
                    }
                })
                .then(data => {
                    setData(data);
                    setError(null);
                })
                .catch(err => {
                    debugger;
                    console.log("Error:", err.name);
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect(accessTokenRequest);
                    } else if(err.name === 'AbortError') {
                        console.log('Fetch aborted');
                    } else {
                        setError(err.message);
                    }
                })
                .finally(() => {
                    setIsPending(false);
                })
            });
    }, [url, instance, accounts, i18n.resolvedLanguage, currentLanguage, error]);

    return { data, isPending, error, notFound };
}

