import { useState} from 'react';
import FormField from "./FormField";
import { useTranslation, Trans } from 'react-i18next';
import { usePost } from './UsePost';
import "./styles/Form.css";
import { Container } from 'react-bootstrap';
const AddReview = () => {
    const { t, i18n } = useTranslation();
    const [values, setValues] = useState({
        itemId:(window.location.pathname).split("/").pop(),
        comments: "",
        projectName: ""
    });

    const baseUrl =process.env.REACT_APP_CATALOGUE_API_URL;
   	const { invokePost } = usePost(`${baseUrl}/Reviews/addReviews`);

	const inputs = [
        {
            id: 1,
            name: "rating",
            type: "rating",
            label: t("review.rating","Your Rating")
        },
        {
            id: 2,
            name: "projectName",
            type: "text",
            label: t("review.name-label","Are you using this component in a project?"),
            placeholder: t("review.name-placeholder","If so, enter the project name"),
        },
        {
            id: 3,
            name: "comments",
            type: "textarea",
            rows: 5,
            label: t("review.comment-label","Your review comments"),
            placeholder: t("review.comment-placeholder","Tell us about your experience"),
            required: true,
            errorMessage: t("review.comment-error","Please provide some details to help others!"),
        },
        
    ]

    const handleSubmit = (e) => {
    invokePost(
        { ...values }
    )
    .then(json => console.log(json))
    .catch(error => console.log('Authorization failed : ' + error.message));
    e.preventDefault();
    };

    const onChange = (e) => {
        console.log(e);
        setValues({...values, [e.target.name]: e.target.value});
    }

    //console.log(values);
    return(
        <>
        <form onSubmit={handleSubmit}>
            
            {inputs.map((input) => (
                <FormField key={input.id} {...input} value={values[input.name]} onChange={onChange} />
            ))}
            <button  className="btn btn-primary"><Trans i18nKey="buttons.submit">Submit</Trans></button>
        </form>
        </>
    );
}

export default AddReview;