import { useParams } from 'react-router-dom';
import { useFetch } from './UseFetch';
import Spinner from './Spinner';
import { Row, Col } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import AddReview from './AddReview';
import Survey from './Survey';
import LocalisedText from './LocalisedText';
import Relationships from './Relationships';
import TranslationEditor from './TranslationEditor';
import { useState  } from 'react';
import {  Header, DivContainer, Header1, Paragraph, RefernceLinks } from './NavibarElements';
import Navbar from './Navbar';
import { MetaTag } from './MetaTag';
import Review from './Review';
import AsyncLocalisedText from './AsyncLocalisedText';
import Collapse from 'react-bootstrap/Collapse'
import Button from 'react-bootstrap/Button';
import {ErrorBoundary} from 'react-error-boundary'

function ErrorFallback({error, resetErrorBoundary}) {
	return (
	  <div role="alert">
		<p>Something went wrong:</p>
		<pre>{error.message}</pre>
		<button onClick={resetErrorBoundary}>Try again</button>
	  </div>
	)
  }

const Details = () => {
	const { id } = useParams();
	const [isEditingTranslation, setIsEditingTranslation] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [translationId, setTranslationId] = useState(0);
	const baseUrl =
		process.env.REACT_APP_CATALOGUE_API_URL;
	const [fetchUrl, setFetchUrl] = useState(`${baseUrl}/details/${id}`);
	const { data: item, isPending, error } = useFetch(fetchUrl);
	const { data: reviews } = useFetch(`${baseUrl}/reviews/${id}`);
	const [open, setOpen] = useState(false);
	
	

	if (isPending) return <Spinner />;
	if (error) throw error;

	const handleEditTranslation = (id) => {
		setTranslationId(id);
		setIsEditingTranslation(true);
	};

	const handleEditTranslationClosing = (id) => {
		setIsEditingTranslation(false);
	};

	const refreshOnUpdate = () => {
		const currentUrl = fetchUrl.split('?');
		const newUrl = currentUrl[0] + '?r=' + Math.random();
		setFetchUrl(newUrl);
	};

	console.log('RENDER DETAILS');
	console.log(item);

	return (
		<>
		  <TranslationEditor
				id={translationId}
				show={isEditingTranslation}
				onClosing={handleEditTranslationClosing}
				onComplete={refreshOnUpdate}
			/>
			
					<Navbar  />
					
					<DivContainer id='repository'>
					 <Row>
						
						<Header1>
							<Trans i18nKey='details.overview' />			
						</Header1>
						<Col col-sm>
                         <Paragraph>
							<AsyncLocalisedText id={id} selector="overview" additionalCssClass="overview-wrapper"/>
						 </Paragraph>
						</Col>
						<Col col-sm>
						<Paragraph>
							<AsyncLocalisedText id={id} selector="highlights" additionalCssClass="highlights-wrapper"/>
						 </Paragraph>

						<div>
						<Header>Tags</Header>
								{item.tags && (
									<ul className='tag-list'>
										{item.tags.map((d) => (
											<MetaTag
												key={d.id}
												name={d.name}
												count={d.count}
												toggleTag='false'
												noCount
	 										/>
										))}
									</ul>
									)}
									{!item.tags && <p>This item is not tagged</p>}	
						</div>	
						<div>				
						<RefernceLinks>
						<table>
						<thead>
							<tr>
								<th>References</th>
							</tr>
						</thead>
						<tbody>
							<tr><td>
							<ul>
								<li>Repository: <a href={item.link} target='_blank' rel='noreferrer'>{item.link}</a></li>
								{item.contactEmail && (
								<li>Contact: <a href={"mailto:" + item.contactEmail} rel='noreferrer'>{item.contactName || item.contactEmail}</a></li>)}
							</ul>
							</td></tr>
						</tbody>
						</table>
						</RefernceLinks>
						</div>	
						
						</Col>
						
					 </Row>
						
					</DivContainer>
					
					<DivContainer id='survey'>
						
					<Row>
						
						<Header1>
						 <Trans i18nKey='details.survey'/>			
						 </Header1>
						 <Col col-sm>
						<p>
							<ErrorBoundary FallbackComponent={ErrorFallback}>
								<Survey selector={item.selector} name={item.name.text}/>
							</ErrorBoundary>
						</p>
						</Col>
					</Row>
						
					</DivContainer>

					<DivContainer id='documentation'>
					<Row>
						
						<Header1><Trans i18nKey='details.documentation' />  </Header1>
						<Col col-sm>
						{item.links.length > 0 &&
								<ul>
								{item.links.map((link) =>
									<li><a href={link.url.replace('{id}',id)}><LocalisedText value={link.localisedText}/></a></li>
								)}
								</ul>}
							{item.links.length === 0 && <p><Trans i18nKey='details.nolinks'>There is no linked documentation</Trans></p>}
						
					 	</Col>
					</Row>
					</DivContainer >
					<DivContainer id='relationship'>
					<Row>
						
						<Header1><Trans i18nKey="relationships.title"/> </Header1>
						<Col col-sm>
							{(item.subjectRelationships || item.DependentRelationships) && ( 
								<Relationships item={item} />
							)}
						</Col>
					</Row>
					 
					</DivContainer>

					<DivContainer  id='reviews'>
					<Row>
						
						<Header1><Trans i18nKey="review.heading"/> </Header1>

						
						<Col col-sm>
							
									{ reviews != null && reviews.slice(-5).map((review) => ( 
										<Review
											key={review.id}
											review={review}
											onEditTranslation={handleEditTranslation} />
									
									)
									)}
									{ reviews != null && reviews.slice(5,reviews.length).map((review) => ( 
								
										<Collapse in={open}>
											<div id="collapse-text" >
												<Review
													key={review.id}
													review={review}
													onEditTranslation={handleEditTranslation} />
											</div>
										</Collapse>									
									)
									)}	
									{ reviews != null && reviews.length > 5 &&
									<div>																
										<Button 
											onClick={() => setOpen(!open)}
											variant="link"
											aria-controls="collapse-text"
											aria-expanded={open}>
											show more/less reviews
										</Button>
									</div>
									}
									
						</Col> 
			        	
						<Col col-sm>
						
							<table className="btn btn-info ">
								<thead >
								  <tr>
									<th ><Trans i18nKey="review.ownreview"/></th>
								  </tr>
								</thead>
								<tbody>
									<tr>
										<td>
										<AddReview />
										</td>
									</tr>
								</tbody>
							</table>
							
						
						</Col>
					</Row>

				</DivContainer>
		</>
	);
};

export default Details;
