import { PencilIcon } from '@primer/octicons-react'
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from 'react-i18next';
import Spinner from './Spinner';
import { useFetch } from './UseFetch';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Header1 } from './NavibarElements';

const AsyncLocalisedText = (props) => {
    const { t } = useTranslation();
    const id = props.id;
    const selector = props.selector;
    const additionalCssClass = props.additionalCssClass;
    const heading = props.heading;

	const baseUrl =
		process.env.REACT_APP_CATALOGUE_API_URL;

    const [fetchUrl] = useState(`${baseUrl}/text/${id}/${selector}`);
	const { data: item, isPending, error, notFound } = useFetch(fetchUrl);

    if (isPending) return <Spinner />;
	if (error) {
        console.log(error);
        debugger;
        throw error;
    }

    if (notFound) {
        return "";
    }

    const text = item.text;
    const canEdit = item.canEdit;
    const isReadOnly = props.readonly;
    const onEditTranslation = props.onEditTranslation;

    const renderTooltip = props => (
        <Tooltip {...props}>{t("buttons.editTranslationTooltip","Edit this text")}</Tooltip>
    );

    const handleEditText = () => {
        console.log("Going to edit " + id);
        onEditTranslation(id);
    }

    return(
        <>
        <ReactMarkdown rehypePlugins={[rehypeRaw]} className={additionalCssClass}>{text}</ReactMarkdown>{canEdit && 
            !isReadOnly && 
            <OverlayTrigger 
                placement="top" 
                overlay={renderTooltip} 
                delay={{ show: 250, hide: 400 }}>
                <Button 
                    className="edit-localised-text-button"
                    onClick={handleEditText}>
                        <PencilIcon />
                </Button>
            </OverlayTrigger>}
        </>
    );
}

export default AsyncLocalisedText;