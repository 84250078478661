import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';

function loadCSSFile(cssFile) {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = cssFile;
  document.head.appendChild(link);
}

const hostname = window.location.hostname;
const subdomain = hostname.split('.')[0].toLowerCase();
const cssFilePath = `/styles/${subdomain}.css`;

loadCSSFile(cssFilePath);

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
console.log(process.env);
console.log("REACT_APP_AUTH_REDIRECT_URL: " + process.env.REACT_APP_AUTH_REDIRECT_URL);
console.log(msalConfig);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
