import { useState } from 'react';

export const MetaTag = (props) => {
    const name = props.name;
    const count = props.count;
    const noCount = props.noCount;
    const [selected, setSelected] = useState(props.selected);

    return(
        <li className={selected ? "selected-tag" : null}>
            <a href="#" alt={name}
                onClick={() => {
                    setSelected(!selected);
                    props.toggleTag(name);
                }}>{name} {!noCount && <>({count})</>}
            </a>
        </li>);
}

