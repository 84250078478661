import React from "react";
import { Container, Row } from 'react-bootstrap';
import { useIsAuthenticated } from "@azure/msal-react";
import Home from './Home';
import Details from './Details';
import OpenApiSpec from "./OpenApiSpec";
import NotFound from './NotFound';
import { Navigation } from './Navigation';
import "./styles/App.css";
import "./styles/FujitsuSans.css"

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <Router>
      <div className="App">
          <Navigation />
          <Container fluid className="content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/details/:id" element={<Details />} />
              <Route path="/openapi/" element={<OpenApiSpec />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Container>
      </div>
    </Router>
  );
}

export default App;
