import { useState, useEffect } from 'react';
import { useFetch } from './UseFetch';
import { useTranslation, Trans } from 'react-i18next';
import { MetaTag } from './MetaTag';

const MetaTagList = (props) => {
	const { t, i18n } = useTranslation();
	const [tags, setTags] = useState(null);
	const baseUrl =
		process.env.REACT_APP_CATALOGUE_API_URL;
	const { data, isPending, error } = useFetch(`${baseUrl}/tags`);

	useEffect(() => {
		if (!isPending) {
			console.log(data);
			setTags(data);
		}
	}, [data, i18n.resolvedLanguage]);

	return (
		<div className='meta-tag-list'>
			{!tags && (
				<p>
					<Trans i18nKey='metatags.loading'>Loading tags...</Trans>
				</p>
			)}

			{tags && (
				<div>
					<p>
						<Trans i18nKey='metatags.title'>Filter Tags</Trans>
					</p>
					<ul className='tag-list'>
						{tags.map((d) => (
							<MetaTag
								key={d.id}
								name={d.name}
								count={d.count}
								toggleTag={props.toggleTag}
							/>
							// return (<li key={d.id}><a href="#">{d.name} ({d.count})</a></li>)
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default MetaTagList;
