const host = window.location.hostname;
export const msalConfig = {
    auth: {
      clientId: "52a6b868-8fad-4579-afa5-7ba78108cb28",
      authority: "https://login.microsoftonline.com/1ee5e0a5-9ae0-4b84-a4bf-055dee55ae46", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URL.replace("{host}", host)
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };

export const appRoles = {
  Assessor: "Assessor"
};