import { useState, useCallback } from 'react';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from 'react-i18next';

export const usePost = (url) => {
    const { instance, accounts } = useMsal();
    const { i18n } = useTranslation();
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    const host = window.location.hostname;
    url = url.replace("{host}", host);
    
    const invokePost = useCallback((payload, handleClose, handleComplete) => {
        const abortController = new AbortController();
        const accessTokenRequest = {
            scopes: ["api://170acadd-bd1d-4283-a67e-c51b61b4ef10/Api.Read"],
            account: accounts[0]
        }

        console.log("Posting to API");
        setIsPending(true);
        instance.acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;
                // Call your API with token
                const headers = new Headers();
                const bearer = `Bearer ${accessToken}`;
            
                headers.append("Authorization", bearer);
                headers.append("Language", i18n.resolvedLanguage);
                headers.append("Content-Type", "application/json");
                console.log("Requesting language", i18n.resolvedLanguage);
            
                const options = {
                    method: "POST",
                    headers: headers,
                    signal: abortController.signal,
                    body: JSON.stringify(payload)
                };

                fetch(url, options)
                .then(res => {
                    console.log("Post: ", res)
                    if(res.ok) {
                        if(handleClose) {
                            handleClose();
                        }
                        const json = res.json();
                        return json;
                    } else {
                        throw Error('Something went wrong');
                    }
                })
                .then(data => {
                    handleComplete();
                })
                .catch(err => {
                    console.log("Error:", err.name);
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect(accessTokenRequest);
                    } else if(err.name === 'AbortError') {
                        console.log('Post aborted');
                    } else {
                        setError(err.message);
                    }
                })
                .finally(() => {
                    setIsPending(false);
                })
            });
    }, [url, instance, accounts, i18n.resolvedLanguage, error]);

    return { data, invokePost, isPending, error };
}

