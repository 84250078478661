import React from "react";
import mermaid from "mermaid";
// import ReactFlow, { useNodesState, useEdgesState, addEdge } from 'react-flow-renderer';
import { Trans, useTranslation } from 'react-i18next';
import { useFetch } from './UseFetch';
import Spinner from './Spinner';
import { Link } from "react-router-dom";

// const reactFlowStyle = {
//     background: '#87CEEB',
//     width: '100%',
//     height: 250,
//   };

mermaid.initialize({
  startOnLoad: true
});

class Mermaid extends React.Component {
  componentDidMount() {
    mermaid.contentLoaded();
  }
  render() {
    return <div className="mermaid">{this.props.chart}</div>;
  }
}


const Relationships = (props) => {

  const { t, i18n } = useTranslation();
	const item = props.item;
	const id = item.id;
	const baseUrl =  process.env.REACT_APP_CATALOGUE_API_URL;
	const { data, isPending, error } = useFetch(
		`${baseUrl}/relationships/${id}`
	);

	if (isPending) return <Spinner />;
	if (error) throw error;

  console.log(data);
  var itemName= item.name.text;
  var composedNodes = 'graph LR;'+id+'['+itemName+']' ;
  var composersNodes = '' ;
  for(var i=0; i<data.composed.length; i++){  
    const relatedItemName = data.composed[i].name;
    const relatedItemID = data.composed[i].id;
    composedNodes = composedNodes +';'+ (id+'['+itemName +']'+'-->'+relatedItemID+'('+`<a href=`+"/details/"+relatedItemID+`>`+relatedItemName+`</a>`+')');
      
  }

  for(var i=0; i<data.composers.length; i++){  
    const relatedItemName = data.composers[i].name;
    const relatedItemID = data.composers[i].id;
    composersNodes = composersNodes +';'+ (relatedItemID+`(<a href=`+"/details/"+relatedItemID+`>`+relatedItemName+`</a>)-->`+id+'['+itemName+']');
      
  }
    
  // const initialNodes =[{
  //   id: 'horizontal-'+ item.id,
  //   data: {
  //       label: (
  //           <>{item.name.text}</>
  //       ),
  //   },
  //   sourcePosition: 'right',
  //   position: { x:0, y: 80 },

  // }];
  //const initialEdges = [];
 
  // for(var i=0; i<item.subjectRelationships.length; i++){
  //   const relatedItemID = item.subjectRelationships[i].relatedItemID;
  //   const relatedItemName = item.subjectRelationships[i].relatedItemName;
    //const subjectID = item.subjectRelationships[i].subjectID;
    //initialNodes.push(firstNode+'-->'+<><a href={"/details/" + relatedItemID}>{relatedItemName}</a></>);
    
    //initialNodes = initialNodes +';'+ (itemName+'-->'+relatedItemName+';');

    //firstNode =firstNode+'-->'+relatedItemName+';';
    
  //   const id = 'horizontal-'+ relatedItemID;
    // const node ={
    //   {item.name.text}+"-->"
  //       id: id,
  //       data: {
  //           label: (
  //                   <><a href={"/details/" + relatedItemID}>{relatedItemName}</a></>
  //           )
  //           },
  //           sourcePosition: 'right',
  //           targetPosition: 'left',
  //           position: { x: 250, y: i*80 }
    
    // };
    // initialNodes.push(node);
  //   const edge={
  //       id: 'horizontal-e' + subjectID + '-' + relatedItemID,                  
  //       source: 'horizontal-'+subjectID,
  //       type: 'straight',
  //       target: 'horizontal-'+ relatedItemID,
  //   }
  //   initialEdges.push(edge);
   //}
   //const [nodes, _, onNodesChange] = useNodesState(initialNodes);
  // const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  // const onConnect = useCallback((params) => setEdges((els) => addEdge(params, els)), []);

  return (
   <>
        
    <Mermaid
        chart={`             
        ${composedNodes}
        ${composersNodes}        
        `}
      />
      
    
      </>
  );
};

export default Relationships;

