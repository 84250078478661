import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import CloseButton from 'react-bootstrap/CloseButton';
import { useTranslation, Trans } from 'react-i18next';
import FormField from './FormField';
import Spinner from './Spinner';
import { useFetch } from './UseFetch';
import { usePost } from './UsePost';
import { useState } from 'react';
import './styles/TranslationEditor.css';

const TranslationEditor = (props) => {
	const { t } = useTranslation();
	const id = props.id;
	const show = props.show;
	const onClosing = props.onClosing;
	const onComplete = props.onComplete;
	const [values, setValues] = useState({
		translation: null,
	});
	const baseUrl =
		process.env.REACT_APP_CATALOGUE_API_URL;
	const {
		data: currentTranslation,
		isPending,
		error,
	} = useFetch(`${baseUrl}/translation/${id}`);
	const { data: postResponse, invokePost } = usePost(`${baseUrl}/translation`);

	if (isPending) return <Spinner />;
	if (error) throw error;

	if (!currentTranslation || !currentTranslation.original) return <></>;

	const input = {
		id: 1,
		name: 'translation',
		type: 'textarea',
		label: t('translationEditor.translation'),
	};

	const handleSubmitComplete = () => {
		setValues({ ...values, translation: null });
		onComplete();
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		invokePost(
			{
				id: currentTranslation.translated.id,
				text: values['translation'],
			},
			onClosing,
			handleSubmitComplete
		);
	};

	const onModalClose = () => {
		setValues({ ...values, translation: null });
		onClosing();
	};

	const onChange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value });
	};

	const onModalOpen = () => {
		console.log('Open: ' + currentTranslation.translated.text);
		setValues({ ...values, translation: currentTranslation.translated.text });
	};

	console.log(values);

	return (
		<Modal show={show} onShow={onModalOpen} onHide={onModalClose}>
			<ModalHeader closeButton='true'>
				<ModalTitle>
					{t('translationEditor.title', 'Edit this Translation')}
				</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<p>{t('translationEditor.introduction', { joinArrays: ' ' })}</p>
				<p>{t('translationEditor.callToAction', { joinArrays: ' ' })}</p>
				<h5>{t('translationEditor.originalHeader')}</h5>
				<div className='translation-text'>
					{currentTranslation.original.text}
				</div>
				<h5>{t('translationEditor.currentHeader')}</h5>
				<div className='translation-text'>
					{currentTranslation.translated.text}
				</div>
				<form className='translation-form' onSubmit={handleSubmit}>
					<FormField
						key={input.id}
						initialValue={currentTranslation.translated.text}
						{...input}
						value={values[input.name]}
						onChange={onChange}
					/>
					<button type='submit'>
						<Trans i18nKey='buttons.submit'>Submit</Trans>
					</button>
				</form>
			</ModalBody>
		</Modal>
	);
};

export default TranslationEditor;
