import { Link } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating'
import LocalisedText from './LocalisedText';
import { Row, Col } from 'react-bootstrap';

const ComposableListItem = (props) => {
    var item = props.item;

    console.log(item);
    return (
        <Row>
            <Link to={`/details/${item.id}`} className="composable-list-item">
                <Col>
                    {/* <img src={item.imageUrl || '/defaultitem.jpg'} alt={item.name.text} /> */}
                    <div className="composable-list-item-main">
                        <h3 className="composable-list-item-name"><LocalisedText value={item.name} readonly/>
                            <Rating className="composable-list-item-rating" initialValue={item.rating} allowFraction="true" size="16" readonly />
                        </h3>
                        <p className="composable-list-item-description"><LocalisedText value={item.description} readonly/></p>
                    </div>
                </Col>
            </Link>
        </Row>
    );
}

export default ComposableListItem;