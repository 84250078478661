import { Link } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating'
import LocalisedText from './LocalisedText';

const ComposableItem = (props) => {
    var item = props.item;
    console.log(item);
    return (
        <Link to={`/details/${item.id}`} className="composable-item">
            <figure>
                {/* <div className="image"><img src={item.imageUrl || '/defaultitem.jpg'} alt={item.name.text} /></div> */}
                <img src={item.imageUrl || '/defaultitem.jpg'} alt={item.name.text} />                <figcaption>
                <h3 className="composable-item-name"><LocalisedText value={item.name} readonly/></h3>
                <div class="rating-wrapper"><Rating initialValue={item.rating} allowFraction="true" size="16" readonly /></div>
                <p className="composable-item-description"><LocalisedText value={item.description} readonly/></p>
                </figcaption>
            </figure>
        </Link>
    );
}

export default ComposableItem;