import { useSearchParams } from 'react-router-dom';
import { useFetch } from './UseFetch';
import { useState } from 'react';
import Spinner from './Spinner';
import { useTranslation } from 'react-i18next';
import 'openapi-explorer';

const OpenApiSpec = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const url = searchParams.get("url")

  console.log(url);
  return(
      <>
        <openapi-explorer spec-url={url} enable-console="false">
        </openapi-explorer>
      </>
  );
}

export default OpenApiSpec;